import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routesPc = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/HomeIndex.vue'),
    meta: {
      favicon: '/T.ico'
    }
  },
  {
    path: '/aircombat',
    name: 'aircombat',
    component: () => import('@/views/home/KillBox.vue'),
    meta: {
      favicon: '/F.ico'
    }
  },
  {
    path: '/airdrops',
    name: 'airdrops',
    component: () => import('@/views/home/Airdrops.vue'),
  },
  {
    path: '/xinaqqy',
    name: 'xinaqqy',
    component: () => import('@/views/home/XinaqqY.vue'),
  },
]

const routerYd = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/movem/HomeIndex.vue'),
    meta: {
      favicon: '/T.ico'
    }
  },
  {
    path: '/aircombat',
    name: 'aircombat',
    component: () => import('@/views/movem/KillBox.vue'),
    meta: {
      favicon: '/F.ico'
    }
  },
  {
    path: '/airdrops',
    name: 'airdrops',
    component: () => import('@/views/movem/Airdrops.vue'),
  },
]


var routes = []
let Adaptive = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
if (navigator.userAgent.match(Adaptive)) {
  routes = routerYd
} else {
  routes = routesPc
}

const router = new VueRouter({
  routes
})

export default router
