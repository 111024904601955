import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';
import './assets/fonts/fonts.css'
import 'element-ui/lib/theme-chalk/index.css';
// 导入js文件 根据窗口调整html-font-size大小
import 'amfe-flexible';
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import { Tab, Tabs, Progress, Icon } from 'vant';

Vue.use(Progress);
Vue.use(ElementUI);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);

Vue.config.productionTip = false

// Function to set the favicon
function setFavicon(faviconURL) {
  let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = faviconURL;
  document.getElementsByTagName('head')[0].appendChild(link);
}

// Set favicon based on route
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.favicon) {
    setFavicon(to.meta.favicon);
  } else {
    // Set a default favicon if no specific favicon is set for the route
    setFavicon('/T.ico');
  }
  next();
});
// 名字
// router.beforeEach((to, from, next) => {
//   if (to.meta && to.meta.title) {
//     document.title = to.meta.title;
//   } else {
//     // Set a default title if no specific title is set for the route
//     document.title = 'TKB';
//   }
//   next();
// });

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
